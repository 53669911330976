import {Component} from "react";
import Icon from "../icon";

class Input extends Component<any> {
    state = {
        hasData: false
    }


    handleInput(event: any) {
        if (!this.state.hasData) {
            this.setState({
                hasData: true
            })
        }
        this.props.onChange(this.props.name, event)
    }


    render() {
        let {type, label, require, name, hasBottom = true} = this.props;
        return <div className={!hasBottom ? 'w-full flex flex-wrap' : 'w-full mb-3 flex flex-wrap'}>
            <div
                className={' group w-full max-x-full relative bg-main px-2 pt-5 pb-1 h-7 box-content border border-[#EDEDED] rounded-lg' + (this.state.hasData ? ' has-data ' : '') + (this.props.errMessage && this.props.error ? ' mb-1 error border-[#E54D2E] shadow shadow-[#E54D2E]' : '')}>
                <input
                    type={type ? type : 'text'}
                    name={name} onChange={(e) => this.handleInput(e)}
                    className="peer text-sm px-1 block min-h-[auto] w-full
                    rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear
                    focus:placeholder:opacity-100
                    peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100
                    motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary
                    "
                    required/>
                <label
                    htmlFor={name}
                    className="pointer-events-none  font-light top-2   absolute left-3 top-0 mb-0 max-w-[90%]
                     origin-top-left truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200
                     ease-out group-[.has-data]:scale-[0.7] group-[.has-data]:-translate-y-[0.6rem] peer-focus:scale-[0.7]
                     peer-focus:-translate-y-[0.6rem] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary
                     group-[.error]:text-[#E54D2E]
                     "
                >{label} {require ? '*' : ''}
                </label>
            </div>
            {this.props.errMessage && this.props.error ? <span className="font-exo text-[11px] text-[#E54D2E]"><Icon icon={'fa-exclamation-circle'}/> {this.props.errMessage}</span> : <></>}

        </div>
    }

    private reset() {

    }
}

export default Input