import LeagueInterface from "interfaces/league";
import moment, { ISO_8601 } from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import logo from "statics/images/logo.png";
import * as SBService from "../../helpers/sportbookService";
import { getAssetFullUrl } from "../../helpers/utils";
import { loaderState, userState } from "../../states/common";
import Icon from "../icon";
import {LeagueTypeEnum, LeagueTypeFiltering} from "../../shared/enums/LeagueTypeEnum";

interface LeagueListingItemProps {
  league: any;
  isJoined: boolean;
  isShowScanBtn: boolean;
}

const LeagueStatus = ({ league }: { league: LeagueInterface }) => {
  let statusMsg = "Vòng tròn tính điểm";
  let color = "#D9D9D9";
  let textColor = "#ffffff";
  const leagueType = LeagueTypeFiltering.find((el) => el.value == league.type)
  if (leagueType) {
    statusMsg = leagueType.label
  }

  if (league.startDateObj.isAfter(moment())) {
    if (league.startRegistrationDateObj.isAfter(moment())) {
    } else {
      color = "#FFE0A1";
      textColor = '#915930'
    }
  } else if (league.endDateObj.endOf('day').isAfter(moment())) {
    color = "#0EB39E";
  }

  return (
    <div
      style={{ backgroundColor: color, color: textColor }}
      className="rounded-full px-2 text-[12px] text-white"
    >
      {statusMsg}
    </div>
  );
};
const NewLeagueListingItem: React.FC<LeagueListingItemProps> = ({
  league,
  isJoined = false,
  isShowScanBtn = false,
}) => {
  const navigate = useNavigate();
  league.startDateObj = moment(league.startDate, ISO_8601);
  league.endDateObj = moment(league.endDate, ISO_8601).endOf('day');
  league.startRegistrationDateObj = moment(
    league.startRegistrationDate,
    ISO_8601
  );
  league.endRegistrationDateObj = moment(league.endRegistrationDate, ISO_8601);
  const [user, setUser] = useRecoilState(userState);
  const [loader, setLoader] = useRecoilState(loaderState);
  const [showModal, setShowModal] = useState(false);
  const [playerInLeague, setPlayerInleague] = useState<any>({
    id: null,
    isCheckin: false,
  });

  const venue = league.venues?.[0] || {};

  //   const now = moment();

  //   const navigateToScanQR = (event: any) => {
  //     event.preventDefault();
  //   };

  const navigateToLeaguePage = () => {
    navigate(`/league/${league.id}`);
  };

  const joinLeague = async (event: any) => {
    event.preventDefault();
    setLoader(true);
    let listJoined = user.leagues.map((league) => league.id);
    if (!listJoined.includes(league.id)) {
      try {
        await SBService.joinLeagueById(league.id);
        toast.success("Tham gia giải đấu thành công");
        let userLeagues = [
          ...user.leagues,
          { id: league.id, banner: league.banner, logo: league.logo },
        ];

        setUser({ ...user, leagues: userLeagues });
      } catch (e: any) {
        if (e.response && e.response.data && e.response.data.error) {
          switch (e.response.data.error) {
            case "leagueFull":
              toast.error("Lỗi, giải đã đủ cơ thủ tham gia");
              break;
            default:
              toast.error("Lỗi, " + e.response.data.error);
              break;
          }
        } else {
          toast.error("Không thể tham gia giải đấu");
        }
      }
    } else {
      toast.error("Có lỗi xảy ra: Bạn đã tham gia giải đấu");
    }
    setShowModal(false);
    setLoader(false);
  };

  //   const checkIn = async () => {
  //     setLoader(true);
  //     try {
  //       await SBService.checkinLeagueById(league.id);
  //       setPlayerInleague({ ...playerInLeague, ...{ isCheckin: true } });
  //       toast.success("Check-In thành công");
  //     } catch (e: any) {
  //       toast.error("Check-In thất bại");
  //     }
  //     setLoader(false);
  //   };

  //   const checkOut = async () => {
  //     setLoader(true);
  //     try {
  //       await SBService.checkoutLeagueById(league.id);
  //       setPlayerInleague({ ...playerInLeague, ...{ isCheckin: false } });
  //       toast.success("Check-out thành công");
  //     } catch (e: any) {
  //       toast.error("Check-out thất bại");
  //     }
  //     setLoader(false);
  //   };

  useEffect(() => {
    const loadUserRec = async () => {
      try {
        const userRec = await SBService.getPlayerInLeagueData(
          league.id,
          user.id
        );
        if (userRec && userRec.data) {
          setPlayerInleague(userRec.data);
        }
      } catch (e: any) {}
    };

    loadUserRec();
  }, []);

  return (
    <div className="bg-[#2A2E53] rounded-lg overflow-hidden">
      <div className="p-2 text-white text-[12px] font-light flex items-center justify-between border-[#1E1E32] border-b-[0.5px]">
        {venue?.address ? (
          <div className="max-w-[70%] truncate">
            {venue?.address} {venue?.address && "-"} {venue?.name}
          </div>
        ) : (
          <div>Chưa rõ địa điểm</div>
        )}
        <LeagueStatus league={league} />
      </div>
      <div
        className="w-full bg-[#2A2E53] flex items-center p-2"
        onClick={() => {
          if (isJoined) navigateToLeaguePage();
        }}
      >
        <section
          className="shrink-0"
          onClick={() => {
            if (!isJoined) navigateToLeaguePage();
          }}
        >
          <img
            className="rounded-md w-[50px] h-[50px] shrink-0"
            alt=""
            src={league.logo ? getAssetFullUrl(league.logo) : logo}
          />
        </section>
        <section className="mx-2 w-full">
          <div className="flex justify-between">
            <div
              className="flex-1"
              onClick={() => {
                if (!isJoined) navigateToLeaguePage();
              }}
            >
              <h3 className="text-[14px] font-medium text-white">
                {league.name}
              </h3>
              {/* <p className="font-light font-exo text-sm color-[#B0B4BA] mt-2">{league.startRegistrationDateObj.format('DD MMMM')} - {league.endRegistrationDateObj.format('DD MMMM')}</p> */}
              {league.startDateObj && (
                <div className="font-light font-exo text-[12px] color-[#B0B4BA] mt-2 flex">
                  <p className="mr-1">Thi đấu: </p>
                  <span>
                    {league.startDateObj.format("DD/MM/YY")} -{" "}
                    {league.endDateObj.format("DD/MM/YY")}
                  </span>
                </div>
              )}
            </div>
            <div
              className="text-right w-[20px] flex items-center justify-end"
              onClick={() => {
                if (!isJoined) navigateToLeaguePage();
              }}
            >
              <Icon icon={"chevron-right"} />
            </div>
          </div>
          {/* {isShowScanBtn ? (
            <a
              className="inline-block btn-primary accent-color w-full mt-3 text-center font-exo uppercase py-1 font-sm"
              onClick={() => navigate(`/scan-qr/${league.id}`)}
            >
              Quét Ngay
            </a>
          ) : (
            <>
              {isJoined ? (
                <>
                  {playerInLeague.id ? (
                    <div className="mt-1">
                      {" "}
                      {playerInLeague.isCheckin ? (
                        <button
                          className="btn w-full btn-primary text-white py-2 heading-6"
                          onClick={() => checkOut()}
                        >
                          Check-Out
                        </button>
                      ) : (
                        <button
                          className="btn w-full btn-primary text-white py-2 heading-6"
                          onClick={() => checkIn()}
                        >
                          Check-In
                        </button>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {now.isAfter(league.endRegistrationDateObj) ||
                  now.isBefore(league.startRegistrationDateObj) ? (
                    <span className="text-sm text-danger font-exo heading-5">
                      Hiện đang không trong thời gian đăng ký giải đấu.
                    </span>
                  ) : (
                    <a
                      className="inline-block btn-primary accent-color w-full mt-3 text-center font-exo py-1 font-sx"
                      onClick={(event) => setShowModal(true)}
                    >
                      Đăng ký{" "}
                      {league.endRegistrationDateObj
                        ? `(hạn chót ${league.endRegistrationDateObj.format(
                            "DD/MM/YY"
                          )})`
                        : ""}
                    </a>
                  )}
                </>
              )}
            </>
          )} */}
        </section>
      </div>
    </div>
  );
};

export default NewLeagueListingItem;
