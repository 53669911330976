import NewLeagueListingItem from "components/league/NewListingItem";
import LeagueInterface from "interfaces/league";
import moment from "moment";
import React, {ReactNode, useEffect, useMemo, useState} from "react";
import toast from "react-hot-toast";
import {useRecoilState} from "recoil";
import {
    TETabs,
    TETabsContent,
    TETabsItem,
    TETabsPane,
} from "tw-elements-react";
import MiniGameListingItem from "../../components/league/MiniGameListingItem";
import * as SBService from "../../helpers/sportbookService";
import {CONSTANT} from "../../shared/constant";
import {
    bottomBarState,
    loaderState,
    showBottomBarState,
    userState,
} from "../../states/common";
import Icon from "../../components/icon";
import {Sheet} from 'react-modal-sheet';
import {LeagueTypeFiltering} from "../../shared/enums/LeagueTypeEnum";
import * as _ from "lodash";
import {TempFilterState} from "../../interfaces/leagueFilter";
import FilteringWithSearch from "../../components/league/FilteringWithSearch";
import FilteringWithSearchWithSub from "../../components/league/FilteringWithSearchWithSub";
import {LeagueFilterStatusEnum} from "../../shared/enums/LeagueFilterStatusEnum";
import {useNavigate} from "react-router-dom";

const LeagueOnUpListingPage = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useRecoilState(loaderState);
    const [, setActive] = useRecoilState(bottomBarState);
    const [bottomBar, setBottomBar] = useRecoilState(showBottomBarState);
    const [leagues, setLeagues] = useState<LeagueInterface[]>([]);
    const [miniGames, setMiniGames] = useState([]);
    const [maxPage, setMaxPage] = useState(1);
    const [isOpenAddressSheet, setIsOpenAddressSheet] = useState(false);
    const [isOpenVenueSheet, setIsOpenVenueSheet] = useState(false);
    const [isOpenLeagueTypeSheet, setIsOpenLeagueTypeSheet] = useState(false);
    const [listVenues, setListVenues] = useState<[]>([]);
    const [listProvince, setListProvince] = useState<[]>([]);

    const [filter, setFilter] = useState<TempFilterState>({
        firstLoad: true,
        page: 1,
        limit: CONSTANT.limit,
        leagueType: '',
        provinceCode: '',
        districtCode: [],
        venues: [],
        filter: LeagueFilterStatusEnum.ONUPCOMING
    });
    const [user,] = useRecoilState(userState);

    const [currentTab, setCurrentTab] = useState("league");

    useEffect(() => {
        const currentFilterStorage = localStorage.getItem("oldFilterLeague");
        if (currentFilterStorage) {
            let cachedFilterObject = JSON.parse(currentFilterStorage);
            if (cachedFilterObject.filter !== undefined) {
                delete cachedFilterObject.filter;
            }
            const tmpFilter = {...filter, ...cachedFilterObject, ...{firstLoad: false}};
            setFilter(tmpFilter);
        } else {
            setFilter({...filter, firstLoad: false});
        }
        setActive("league");
        setBottomBar(true);

        loadAllVenue('', [])
        loadAllProvince()
    }, []);

    const listJoinedId = useMemo(
        () => (user.leagues ? user.leagues.map((league) => league.id) : ""),
        [user]
    );

    const loadAllLeague = async (tab = "league") => {
        if ((filter.page === 1 && leagues.length === 0) || filter.page < maxPage) {
            setLoader(true);
            try {
                let data;
                if (tab === "minigame") {
                    data = await SBService.getMiniGame(filter);
                } else {
                    data = await SBService.getLeagues(filter);
                }

                if (data && data.data.length > 0) {
                    if (data.hasNextPage) {
                        setMaxPage(maxPage + 1);
                        setFilter({...filter, page: data.page});
                    } else {
                        setMaxPage(1);
                    }
                    if (tab === "minigame") {
                        setMiniGames(miniGames.concat(data.data));
                    } else {
                        setLeagues(leagues.concat(data.data));
                    }
                }
            } catch (e: any) {
                if (e.response && e.response.data && e.response.data.error) {
                    toast("Có lỗi xảy ra: " + e.response.data.error);
                } else {
                    toast("Có lỗi xảy ra: " + e.message);
                }
            }
            setLoader(false);
        }
    };

    const loadAllProvince = async () => {
        const data = await SBService.loadProvince();
        if (data.data.length > 0) {
            const provinceData = data.data.map((province: any) => ({value: province.code, label: province.name}));
            setListProvince(provinceData);
        }

    }

    const loadAllVenue = async (provinceCode: string, districtCode: Array<String>) => {
        const data = await SBService.loadVenueData(provinceCode, districtCode);
        if (data.data) {
            const venueData = data.data.map((venue: any) => ({value: venue.id, label: venue.name}));
            setListVenues(venueData)
        }
    }

    const loadDistrictOfProvince = async (provinceCode: string) => {
        const data = await SBService.loadDistrictProvince(provinceCode);
    }

    const handleBasicClick = (tab: string) => {
        setCurrentTab(tab);
        loadAllLeague(tab);
    };


    const applyFiltering = (type: string, value: any) => {
        let tmp: any = _.cloneDeep(filter)
        if (_.isObject(value) && !_.isArray(value)) {
            tmp = {...tmp, ...value};
        } else {
            tmp[type] = value;
        }
        const filteringData = {...tmp, page: 1}
        setLeagues([]);
        setFilter(filteringData);
        localStorage.setItem('oldFilterLeague', JSON.stringify(filteringData));
        loadAllLeague(currentTab)
        switch (type) {
            case 'leagueType':
                setIsOpenLeagueTypeSheet(false)
                break;
            case 'provinceCode':
                setIsOpenAddressSheet(false)
                break;
            case 'venues':
                setIsOpenVenueSheet(false)
                break;
        }
    }

    useEffect(() => {
        if (!filter.firstLoad) {
            loadAllLeague(currentTab)
        }
    }, [filter]);

    const switchFilterEnded = () => {
        navigate('/league-end');
    }

    return (
        <div>
            <h2 className="text-3xl uppercase pt-8 w-full text-center">
                danh sách giải đấu
            </h2>
            <div className="bg-[#1E1E32]">
                <div className="league-content-tab rounded-xl bg-[#1E1E32] w-full mt-5">
                    <TETabs className="w-full mt-2">
                        <TETabsItem
                            wrapperClass={"w-full bg-[#1E1E32]"}
                            className="w-full py-3 customTab"
                            onClick={() => handleBasicClick("league")}
                            active={currentTab === "league"}
                            tag="button"
                            color="primary"
                        >
                            Giải đấu
                        </TETabsItem>
                        <TETabsItem
                            wrapperClass={"w-1/2 bg-[#1E1E32]"}
                            className="w-full py-3 hidden"
                            onClick={() => handleBasicClick("minigame")}
                            active={currentTab === "minigame"}
                            tag="button"
                            color="primary"
                        >
                            Mini Game
                        </TETabsItem>
                    </TETabs>
                </div>
                <TETabsContent className="text-[#B0B4BA] bg-[#1E1E32] -mt-2">
                    <TETabsPane className="p-4 text-sm flex flex-col gap-3" show={true}>
                        <div className="filter-tab flex flex-wrap relative">
                            <div className="w-1/2 p-1 relative">
                                <label className="bg-[#2D2D46] rounded inline-block w-full p-2 pr-5 relative"
                                       onClick={() => setIsOpenAddressSheet(true)}
                                >
                                    Khu vực {filter.provinceCode ? <span className="text-[#53b9ab]">(1)</span> : <></>}
                                    <span className="absolute right-2">
                       <Icon icon={"chevron-down"}/>
                    </span>
                                </label>
                            </div>
                            <div className="w-1/2 p-1">
                                <label className="w-full inline-block bg-[#2D2D46] rounded p-2 pr-5 relative"
                                       onClick={() => setIsOpenVenueSheet(true)}
                                >
                                    Câu lạc bộ {filter.venues.length > 0 ? <span className="text-[#53b9ab]">({filter.venues.length})</span> : <></>}
                                    <span className="absolute right-2">
                       <Icon icon={"chevron-down"}/>
                    </span>
                                </label>
                            </div>
                            <div className="w-1/2 p-1">
                                <label className="bg-[#2D2D46] inline-block w-full rounded p-2 pr-5 relative"
                                       onClick={() => setIsOpenLeagueTypeSheet(true)}>
                                    Loại giải {filter.leagueType ? <span className="text-[#53b9ab]">(1)</span> : <></>}
                                    <span className="absolute right-2">
                       <Icon icon={"chevron-down"}/>
                    </span>
                                </label>
                            </div>

                            <div className="flex w-1/2 text-[#0BD8B6]  items-center justify-end pr-2"><span onClick={() => switchFilterEnded()}
                                className="pr-2">Giải đã kết thúc</span> <span className="block"><Icon
                                icon="arrow-right"/></span></div>
                        </div>
                        {leagues.map(
                            (league: any, index) => {
                                return (
                                    <NewLeagueListingItem
                                        key={`league-listing-item-${index}`}
                                        isShowScanBtn={false}
                                        isJoined={listJoinedId.includes(league.id)}
                                        league={league}
                                    />
                                );
                            }
                        )}
                    </TETabsPane>
                    <TETabsPane
                        className="font-exo text-sm p-4"
                        show={currentTab === "minigame"}
                    >
                        {leagues.length > 0 ? (
                            leagues.map((league: any, index) => {
                                return (
                                    <MiniGameListingItem
                                        key={`league-listing-item-${index}`}
                                        isShowScanBtn={false}
                                        isJoined={listJoinedId.includes(league.id)}
                                        league={league}
                                    />
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </TETabsPane>
                </TETabsContent>
            </div>
            <Sheet snapPoints={[0.67]} initialSnap={0} isOpen={isOpenLeagueTypeSheet} onClose={() => {
                setIsOpenLeagueTypeSheet(false);
            }}>
                <Sheet.Container>
                    <Sheet.Header>
                        <h3 className="text-2xl uppercase p-4 w-full text-center">Loại giải</h3>
                    </Sheet.Header>
                    <Sheet.Content>
                        <div className="filter-form w-full bg-[#2D2D46] rounded px-3">
                            <FilteringWithSearch items={LeagueTypeFiltering} currentFiltering={filter.leagueType}
                                                 isEquals={true} onChangeHandler={applyFiltering}
                                                 filterType="leagueType"/>
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop onTap={() => {
                    setIsOpenLeagueTypeSheet(false);
                }}/>
            </Sheet>

                <Sheet snapPoints={[0.67]} initialSnap={0} isOpen={isOpenVenueSheet} onClose={() => {
                setIsOpenVenueSheet(false);
            }}>
                <Sheet.Container>
                    <Sheet.Header>
                        <h3 className="text-2xl uppercase p-4 w-full text-center">Câu lạc bộ</h3>
                    </Sheet.Header>
                    <Sheet.Content>
                        <div className="filter-form w-full bg-[#2D2D46] rounded px-3">
                            <FilteringWithSearch items={listVenues} currentFiltering={filter.venues} isEquals={false}
                                                 onChangeHandler={applyFiltering} filterType="venues"/>
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop onTap={() => {
                    setIsOpenVenueSheet(false);
                }}/>
            </Sheet>

           <Sheet snapPoints={[0.67]} initialSnap={0} isOpen={isOpenAddressSheet} onClose={() => {
                setIsOpenAddressSheet(false);
            }}>
                <Sheet.Container>
                    <Sheet.Header>
                        <h3 className="text-2xl uppercase p-4 w-full text-center">Khu vực</h3>
                    </Sheet.Header>
                    <Sheet.Content>
                        <div className="filter-form w-full bg-[#2D2D46] rounded px-3 h-[100%] overflow-auto">
                            <FilteringWithSearchWithSub items={listProvince} currentFiltering={filter.provinceCode}
                                                 isEquals={true} onChangeHandler={applyFiltering}
                                                 filterType="provinceCode" isHasSubFilter={true}
                                                 subCurrentFiltering={filter.districtCode}
                                                 subFilteringType={'districtCode'}/>
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop onTap={() => {
                    setIsOpenAddressSheet(false);
                }}/>
            </Sheet>
        </div>
    )
        ;
};

export default LeagueOnUpListingPage;
