import React, {useEffect, useState} from "react";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import Icon from "../icon";
import * as _ from 'lodash'
import Input from "../form/Input";
import slugify from "react-slugify";
import * as SBService from "../../helpers/sportbookService";
import toast from "react-hot-toast";
import {filter} from "lodash";
import SmallerInput from "../form/SmallerInput";


const checkBoxStyling = {
    '& svg': {
        backgroundColor: '#b0b4ba',
        padding: '4px',
        borderRadius: '4px',
        color: '#fff',
    },
    '&.Mui-checked': {
        color: '#53B9AB',
    },
    '&.Mui-checked svg': {
        backgroundColor: '#53B9AB',
    },
}

interface FilteringWithSearchComponentWithSubProps {
    items: any[];
    currentFiltering: any,
    onChangeHandler: (type: string, value: any) => void,
    filterType: string
    isEquals: boolean
    isHasSubFilter: boolean
    subCurrentFiltering: string | undefined | null | string[],
    subFilteringType: string | undefined | null
}


const FilteringWithSearchComponentWithSub: React.FC<FilteringWithSearchComponentWithSubProps> = ({
                                                                              items,
                                                                              onChangeHandler,
                                                                              currentFiltering,
                                                                              filterType,
                                                                              isEquals = true,
                                                                              isHasSubFilter = false,
                                                                              subCurrentFiltering = undefined,
                                                                              subFilteringType = undefined
                                                                          }) => {
    const [listItems, setListItem] = useState(items);
    const [listOriginItems, setListOriginItems] = useState(items);
    const [isSearching, setIsSearching] = useState(false);
    const [tempFilterState, setTempFilterState] = useState(currentFiltering)
    const [baseTempFilterState, setBaseTempFilterState] = useState(currentFiltering)
    const [isDisplaySubFiltering, setIsDisplaySubFiltering] = useState(false)
    const [isEqualFilter, setIsEqualFilter] = useState(isEquals);
    const [selectedBaseObject, setSelectedBaseObject] = useState<any>(null);
    const [currentFilterType, setCurrentFilterType] = useState<any>(filterType);
    const [isAllowRender , setIsAllowRender] = useState(false);

    const filterListItem = (label: string, event: any) => {
        if (event.target.value) {
            setIsSearching(true);
            const search = slugify(event.target.value);
            setListItem(listOriginItems.filter(item => {
                return slugify(item.label).includes(search)
            }))
        } else {
            setIsSearching(false);
            setListItem(items)
        }
    }

    const applyFiltering = () => {
        if (currentFilterType == subFilteringType) {
            let tmpObj: any = {};
            tmpObj[filterType] = baseTempFilterState;
            if (subFilteringType) {
                tmpObj[subFilteringType] = tempFilterState;
            }
            onChangeHandler(filterType, tmpObj)
        } else {
            onChangeHandler(currentFilterType, tempFilterState)
        }

    }

    const handleChangeCheckBox = async (event: any, checked: boolean, filterType: string) => {
        let currentFilter = _.cloneDeep(tempFilterState)
        switch (filterType) {
            case 'leagueType':
                if (checked) {
                    if (event.target.value == 'all') {
                        currentFilter = ''
                    } else {
                        currentFilter = event.target.value
                    }
                } else {
                    currentFilter = ''
                }
                break;
            case 'provinceCode':
                if (event.target.value == 'all') {
                    currentFilter = ''
                } else {
                    currentFilter = event.target.value
                }
                break;
            case 'districtCode':
                if (checked) {
                    if (event.target.value == 'all') {
                        currentFilter = []
                    } else {
                        if (!currentFilter) {
                            currentFilter = [event.target.value]
                        } else {
                            currentFilter.push(event.target.value)
                        }
                    }
                } else {
                    currentFilter = _.filter(currentFilter, val => val !== event.target.value)
                }
                break;
            case 'venues':
                if (checked) {
                    if (event.target.value == 'all') {
                        currentFilter = []
                    } else {
                        currentFilter.push(event.target.value)
                    }
                } else {
                    currentFilter = _.filter(currentFilter, val => val !== event.target.value)
                }
                break;
        }
        setTempFilterState(currentFilter)
        if (isHasSubFilter && currentFilter) {
            if (subFilteringType == 'districtCode') {
                try {
                    setIsDisplaySubFiltering(true)
                } catch (e: any) {
                    if (e.response && e.response.data && e.response.data.error) {
                        toast("Có lỗi xảy ra: " + e.response.data.error);
                    } else {
                        toast("Có lỗi xảy ra: " + e.message);
                    }
                }
            }
        }
    };

    const loadSubData = async (value: any) => {
        const loadSubData = await SBService.loadDistrictProvince(value);
        if (loadSubData && loadSubData.data.length > 0) {
            const districtData = loadSubData.data.map((district: any) => ({
                value: district.code,
                label: district.name
            }));
            return districtData;
        }
        return null;
    }

    useEffect(() => {
        if (isHasSubFilter && currentFiltering) {
            setIsDisplaySubFiltering(true)
            setIsEqualFilter(false)
        } else {
            setIsEqualFilter(isEquals)
        }

    }, []);


    useEffect(() => {
        if (isDisplaySubFiltering) {
            const baseItem = _.find(items, item => item.value === tempFilterState)
            if (baseItem) {
                setBaseTempFilterState(baseItem.value)
                setSelectedBaseObject(baseItem)
                loadSubData(baseItem.value).then((subData) => {
                    setIsSearching(false)
                    setListItem(subData);
                    setListOriginItems(subData)
                })
                setIsEqualFilter(false)
                setCurrentFilterType(subFilteringType)
                setTempFilterState(subCurrentFiltering || [])
            }
        } else {
            setIsSearching(false)
            setSelectedBaseObject(null);
            setListItem(items)
            setListOriginItems(items)
            setSelectedBaseObject(null);
            setTempFilterState(baseTempFilterState)
            setIsEqualFilter(true)
            setCurrentFilterType(filterType)
        }

    }, [isDisplaySubFiltering]);

    return <div>
        <div className="header-filter px-2 my-2">
            <div className="w-full flex">
                <div className="mr-1 w-full">
                    <SmallerInput key={'filter-area'} hasBottom={false} label={"Tìm kiếm..."}
                                  onChange={filterListItem}/>
                </div>
                <button
                    className="btn-primary text-white w-[150px] rounded-[0.5rem] overflow-hidden relative z-0 bg-[#FF5821] text-center"
                    color="inherit" onClick={applyFiltering}>Xác Nhận
                </button>
            </div>
            {selectedBaseObject ? <div onClick={() => {
                setIsDisplaySubFiltering(false)
            }} className="text-[#53b9ab] mt-4 text-xl"><Icon icon="arrow-left"></Icon><span
                className="ml-2">{selectedBaseObject.label}</span></div> : <></>}
        </div>
        <div className="sheet-container-scroller overflow-y-auto">
            <div className="px-2 py-1">
                {!isSearching ? <FormControlLabel control={<Checkbox checked={tempFilterState.length == 0}
                                                                     icon={<Icon icon="fa-minus"/>}
                                                                     checkedIcon={<Icon icon="fa-check"/>}
                                                                     sx={checkBoxStyling}/>}
                                                  value={'all'}
                                                  onChange={(e, checked) => handleChangeCheckBox(e, checked, currentFilterType)}
                                                  label="Chọn tất cả"/> : <></>}

            </div>

            {
                listItems.length > 0 ?
                    listItems.map((item, index) => <FormGroup
                            key={`league-filter-${filterType}-item-${index}`} className="px-2 py-1">
                            <FormControlLabel
                                control={<Checkbox
                                    checked={isEqualFilter ? tempFilterState == item.value : tempFilterState.includes(item.value)}
                                    icon={<Icon icon="fa-minus"/>}
                                    checkedIcon={<Icon icon="fa-check"/>}
                                    sx={checkBoxStyling}/>}

                                value={item.value}
                                onChange={(e, checked) => handleChangeCheckBox(e, checked, currentFilterType)}
                                label={item.label}/>
                        </FormGroup>
                    ) : <></>
            }
        </div>

    </div>
}

export default FilteringWithSearchComponentWithSub