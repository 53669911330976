import NewLeagueListingItem from "components/league/NewListingItem";
import LeagueInterface from "interfaces/league";
import moment from "moment";
import React, {ReactNode, useEffect, useMemo, useState} from "react";
import toast from "react-hot-toast";
import {useRecoilState} from "recoil";
import {
    TETabs,
    TETabsContent,
    TETabsItem,
    TETabsPane,
} from "tw-elements-react";
import MiniGameListingItem from "../../components/league/MiniGameListingItem";
import * as SBService from "../../helpers/sportbookService";
import {CONSTANT} from "../../shared/constant";
import {
    bottomBarState,
    loaderState,
    showBottomBarState,
    userState,
} from "../../states/common";
import Icon from "../../components/icon";
import {TempFilterState} from "../../interfaces/leagueFilter";
import {LeagueFilterStatusEnum} from "../../shared/enums/LeagueFilterStatusEnum";
import {useNavigate} from "react-router-dom";

const LeagueEndedListing = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useRecoilState(loaderState);
    const [, setActive] = useRecoilState(bottomBarState);
    const [bottomBar, setBottomBar] = useRecoilState(showBottomBarState);
    const [leagues, setLeagues] = useState<LeagueInterface[]>([]);
    const [miniGames, setMiniGames] = useState([]);
    const [maxPage, setMaxPage] = useState(1);

    const [filter, setFilter] = useState<TempFilterState>({
        firstLoad: false,
        page: 1,
        limit: CONSTANT.limit,
        leagueType: '',
        provinceCode: '',
        districtCode: [],
        venues: [],
        filter: LeagueFilterStatusEnum.ENDED
    });
    const [user,] = useRecoilState(userState);

    const [currentTab, setCurrentTab] = useState("league");

    useEffect(() => {
        loadAllLeague(currentTab);
        setActive("league");
        setBottomBar(true);

    }, []);

    const listJoinedId = useMemo(
        () => (user.leagues ? user.leagues.map((league) => league.id) : ""),
        [user]
    );

    const loadAllLeague = async (tab = "league") => {
        if ((filter.page === 1 && leagues.length === 0) || filter.page < maxPage) {
            setLoader(true);
            try {
                let data;
                if (tab === "minigame") {
                    data = await SBService.getMiniGame(filter);
                } else {
                    data = await SBService.getLeagues(filter);
                }

                if (data && data.data.length > 0) {
                    if (data.hasNextPage) {
                        setMaxPage(maxPage + 1);
                        setFilter({...filter, page: data.page});
                    } else {
                        setMaxPage(1);
                    }
                    if (tab === "minigame") {
                        setMiniGames(miniGames.concat(data.data));
                    } else {
                        setLeagues(leagues.concat(data.data));
                    }
                }
            } catch (e: any) {
                if (e.response && e.response.data && e.response.data.error) {
                    toast("Có lỗi xảy ra: " + e.response.data.error);
                } else {
                    toast("Có lỗi xảy ra: " + e.message);
                }
            }
            setLoader(false);
        }
    };


    const handleBasicClick = (tab: string) => {
        setCurrentTab(tab);
        loadAllLeague(tab);
    };


    useEffect(() => {
        loadAllLeague(currentTab)
    }, [filter.page]);

    const switchFilterEnded = () => {
        navigate('/league')
    }

    return (
        <div>
            <h2 className="text-3xl uppercase pt-8 w-full text-center mt-5">
                Giải đã kết thúc
            </h2>
            <div className="bg-[#1E1E32]">
                <TETabsContent className="text-[#B0B4BA] bg-[#1E1E32] -mt-2">
                    <TETabsPane className="p-4 text-sm flex flex-col gap-3" show={true}>
                        <div className="filter-tab flex flex-wrap relative">


                            <div className="flex w-1/2 text-[#0BD8B6]  items-center justify-start pr-2">
                                <span className="block"><Icon
                                    icon="arrow-left"/></span>
                                <span
                                    onClick={() => switchFilterEnded()}
                                    className="pl-2">Giải đang diễn ra</span></div>
                        </div>
                        {leagues.map(
                            (league: any, index) => {
                                return (
                                    <NewLeagueListingItem
                                        key={`league-listing-item-${index}`}
                                        isShowScanBtn={false}
                                        isJoined={listJoinedId.includes(league.id)}
                                        league={league}
                                    />
                                );
                            }
                        )}
                    </TETabsPane>
                    <TETabsPane
                        className="font-exo text-sm p-4"
                        show={currentTab === "minigame"}
                    >
                        {leagues.length > 0 ? (
                            leagues.map((league: any, index) => {
                                return (
                                    <MiniGameListingItem
                                        key={`league-listing-item-${index}`}
                                        isShowScanBtn={false}
                                        isJoined={listJoinedId.includes(league.id)}
                                        league={league}
                                    />
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </TETabsPane>
                </TETabsContent>
            </div>
        </div>
    )
        ;
};

export default LeagueEndedListing;
