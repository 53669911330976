export enum LeagueTypeEnum {
    ROUNDROBIN = 'ROUND-ROBIN',
    TEAM = 'TEAM',
    MASTER = 'MASTER',
    ELIMINATION = 'ELIMINATION',
}

export const LeagueTypeFiltering = [
    {
        'value': LeagueTypeEnum.ROUNDROBIN,
        'label': 'Vòng tròn tính điểm',
    },
    {
        'value': LeagueTypeEnum.ELIMINATION,
        'label': 'Đấu loại trực tiếp',
    },
]