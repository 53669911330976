import { Variants } from "framer-motion";
import LeagueStandingPage from "pages/league/league";
import LeagueDetail from "pages/league/league-detail";
import LeaguePublic from "pages/league/leaguePublic";
import UserLeagueSchedule from "pages/league/schedule";
import PlayoffAllSchedule from "pages/league/schedule/AllSchedule";
import { createBrowserRouter } from "react-router-dom";
import ForgotPasswordPage from "./pages/auth/forgotPassword";
import LoginPage from "./pages/auth/login";
import LogoutPage from "./pages/auth/logout";
import RegisterPage from "./pages/auth/register";
import AuthLayout from "./pages/authLayout";
import CalibrateUser from "./pages/calibrate";
import Layout from "./pages/layout";
import LeagueOnUpListingPage from "./pages/league/leagueOnUpComingListingPage";
import LeagueMatching from "./pages/league/leagueMatching";
import LeagueMatchingConfirmResultPage from "./pages/league/leagueMatchingConfirmResult";
import LeagueMatchingSubmitPage from "./pages/league/LeagueMatchingSubmitPage";
import MyQR from "./pages/QR/myQR";
import ScanQR from "./pages/QR/scanQR";
import ScanQRJoinedLeagueListing from "./pages/QR/scanQRJoinedLeagueListing";
import ScanQRSelection from "./pages/QR/scanQRSelection";
import Profile from "./pages/user/profile";
import RankingHistory from "./pages/user/rankingHistory";
import RankingUser from "./pages/user/rankingUser";
import LeagueMatchHistory from "./pages/league/league-detail/history";
import LeagueEndedListing from "./pages/league/leagueEndedListing";

const routeVariants: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const router = createBrowserRouter([
  {
    path: "league-public/:id",
    element: <LeaguePublic />,
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: <LoginPage />,
        index: true,
      },
      {
        path: "logout",
        element: <LogoutPage />,
        index: false,
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordPage />,
      },
      {
        path: "register",
        element: <RegisterPage />,
      },
    ],
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Profile />,
        index: true,
      },
      {
        path: "my-qr",
        element: <MyQR />,
      },
      {
        path: "profile",
        element: <Profile />,
        index: true,
      },
      {
        path: "scan-qr-selection",
        element: <ScanQRSelection />,
      },
      {
        path: "scan-qr-selection/:mode",
        element: <ScanQRSelection />,
      },
      {
        path: "scan-qr-joined-league",
        element: <ScanQRJoinedLeagueListing />,
      },
      {
        path: "scan-qr",
        element: <ScanQR />,
      },
      {
        path: "scan-qr/:mode",
        element: <ScanQR />,
      },
      {
        path: "league",
        element: <LeagueOnUpListingPage />,
      },
      {
        path: "league-end",
        element: <LeagueEndedListing />,
      },
      {
        path: "league/:id",
        element: <LeagueDetail />,
      },
      {
        path: "league/:id/history",
        element: <LeagueMatchHistory />,
      },
      {
        path: "league/playoff/:id/schedule",
        element: <PlayoffAllSchedule />,
      },
      {
        path: "league/:id/detail/:playerId",
        element: <UserLeagueSchedule />,
      },
      {
        path: "league/:id/detail",
        element: <LeagueStandingPage />,
      },
      {
        path: "league/:leagueId/competitor/:playerId",
        element: <LeagueMatching />,
      },
      {
        path: "league/:leagueId/competitor/:playerId/match/:matchId",
        element: <LeagueMatching />,
      },
      {
        path: "league/:leagueId/match/:matchId",
        element: <LeagueMatchingSubmitPage />,
      },
      {
        path: "league/:leagueId/match/:matchId/result",
        element: <LeagueMatchingConfirmResultPage />,
      },
      {
        path: "calibrate/:id",
        element: <CalibrateUser />,
      },
      {
        path: "ranking/:id",
        element: <RankingUser />,
      },
      {
        path: "/profile/rank-history",
        element: <RankingHistory />,
      },
    ],
  },
]);
