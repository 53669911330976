import React, {useEffect, useState} from "react";
import {LeagueTypeFiltering} from "../../shared/enums/LeagueTypeEnum";
import {Button, Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import Icon from "../icon";
import * as _ from 'lodash'
import SmallerInput from "../form/SmallerInput";
import slugify from "react-slugify";
import {TempFilterState} from "../../interfaces/leagueFilter";


const checkBoxStyling = {
    '& svg': {
        backgroundColor: '#b0b4ba',
        padding: '4px',
        borderRadius: '4px',
        color: '#fff',
    },
    '&.Mui-checked': {
        color: '#53B9AB',
    },
    '&.Mui-checked svg': {
        backgroundColor: '#53B9AB',
    },
}

interface FilteringWithSearchComponentProps {
    items: any[];
    currentFiltering: any,
    onChangeHandler: (type: string, value: any) => void,
    filterType: string
    isEquals: boolean
}


const FilteringWithSearch: React.FC<FilteringWithSearchComponentProps> = ({
                                                                              items,
                                                                              onChangeHandler,
                                                                              currentFiltering,
                                                                              filterType,
                                                                              isEquals = true
                                                                          }) => {
    const [listItems, setListItem] = useState(items);
    const [isSearching, setIsSearching] = useState(false);
    const [tempFilterState, setTempFilterState] = useState(currentFiltering)

    const filterListItem = (label: string, event: any) => {
        if (event.target.value) {
            setIsSearching(true);
            const search = slugify(event.target.value);
            setListItem(items.filter(item => {
                return slugify(item.label).includes(search)
            }))
        } else {
            setIsSearching(false);
            setListItem(items)
        }
    }

    const applyFiltering = () => {
        onChangeHandler(filterType, tempFilterState)
    }

    const handleChangeCheckBox = (event: any, checked: boolean, filterType: string) => {
        let currentFilter = _.cloneDeep(tempFilterState)
        switch (filterType) {
            case 'leagueType':
                if (checked) {
                    if (event.target.value == 'all') {
                        currentFilter = ''
                    } else {
                        currentFilter = event.target.value
                    }
                } else {
                    currentFilter = ''
                }
                break;
            case 'provinceCode':
                if (checked) {
                    if (event.target.value == 'all') {
                        currentFilter = ''
                    } else {
                        currentFilter = event.target.value
                    }
                } else {
                    currentFilter = ''
                }
                break;
            case 'districtCode':
                if (checked) {
                    if (event.target.value == 'all') {
                        currentFilter = []
                    } else {
                        currentFilter.push(event.target.value)
                    }
                } else {
                    currentFilter = _.filter(currentFilter, val => val !== event.target.value)
                }
                break;
            case 'venues':
                if (checked) {
                    if (event.target.value == 'all') {
                        currentFilter = []
                    } else {
                        currentFilter.push(event.target.value)
                    }
                } else {
                    currentFilter = _.filter(currentFilter, val => val !== event.target.value)
                }

                break;
        }
        setTempFilterState(currentFilter)
    };

    return <div>
        <div className="header-filter flex px-2 mb-2 mt-1">
            <div className="mr-1 w-full">
                <SmallerInput key={'filter-area'} hasBottom={false} label={"Tìm kiếm..."}
                       onChange={filterListItem}/>
            </div>
            <button
                className="btn-primary text-white w-[120px] rounded-[0.5rem] overflow-hidden relative z-0 bg-[#FF5821] text-center"
                color="inherit"  onClick={applyFiltering}>Xác Nhận</button>

        </div>
        <div className="sheet-container-scroller overflow-y-auto">
            <div className="px-2 py-1">
                {!isSearching ? <FormControlLabel control={<Checkbox checked={tempFilterState.length == 0}
                                                                     icon={<Icon icon="fa-minus"/>}
                                                                     checkedIcon={<Icon icon="fa-check"/>}
                                                                     sx={checkBoxStyling}/>}
                                                  value={'all'}
                                                  onChange={(e, checked) => handleChangeCheckBox(e, checked, filterType)}
                                                  label="Chọn tất cả"/> : <></>}

            </div>

            {
                listItems.length > 0 ?
                    listItems.map((item, index) => <FormGroup
                            key={`league-filter-${filterType}-item-${index}`} className="px-2 py-1">
                            <FormControlLabel
                                control={<Checkbox
                                    checked={isEquals ? tempFilterState == item.value : tempFilterState.includes(item.value)}
                                    icon={<Icon icon="fa-minus"/>}
                                    checkedIcon={<Icon icon="fa-check"/>}
                                    sx={checkBoxStyling}/>}

                                value={item.value}
                                onChange={(e, checked) => handleChangeCheckBox(e, checked, filterType)}
                                label={item.label}/>
                        </FormGroup>
                    ) : <></>
            }
        </div>
    </div>
}

export default FilteringWithSearch